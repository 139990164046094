// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.qa.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  version: '2.000.0',
  production: true,
  errMsg: 'Failed to complete. Please try again.',
  partner: false,
  config: {
      "stage": "prod",
      "appName": "custom-dashboard-ui",
      "appDisplayName":"75F",
      "caretakerUrl": "https://caretaker.prod.service.75f.io/api/v1",
      "haystackUrl": "https://silo.prod.service.75f.io/",
      "tagsUrl": "https://silo.prod.service.75f.io/v2/sitePointTags/",
      "dashboardServiceUrl": "https://table-maker-api-75f-service-prod.azurewebsites.net/",
      "tableMakerUrl": "https://table-maker-api.prod.service.75f.io/"
  }
};